import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useSelector, useDispatch} from 'react-redux'
import useSettingData from '../../../../helpers/components/UseSettingData'
import {useThemeMode} from '../../../../partials'
import {UseCustom} from '../../../../partials/layout/Custom-field/Custom-field'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {mode} = useThemeMode() // Get the current theme mode from context
  let modules = localStorage.getItem('modules')
  const organizationSettingData = modules ? JSON.parse(modules) : []
  //  const [state,setSTate] = useState();
  const {customFields, checkForCustomField} = UseCustom()

  return (
    <div className={`sidebar-menu-main text-${mode === 'dark' ? 'light' : 'dark'}`}>
      {organizationSettingData?.map((item: any) => {
        let path = `/${item.title.replace(' ', '-').toLowerCase()}`
        if (item?.child?.length > 0) {
          return (
            <SidebarMenuItemWithSub key={item.title} to={path} title={item.title} icon={item.icon}>
              {item.child?.map((row: any, i: any) => {
                let path =
                  `/${item.title.replace(' ', '-').toLowerCase()}/` +
                  row.title.replace(' ', '-').toLowerCase()

                if (row.display_on_board)
                  return (
                    <span onClick={(e) => checkForCustomField(row.module_id)} key={i}>
                      <SidebarMenuItem
                        key={row.title}
                        to={path}
                        title={row.title}
                        icon={row.icon}
                        hasBullet={false}
                      />
                    </span>
                  )
              })}
            </SidebarMenuItemWithSub>
          )
        } else {
          return (
            <span onClick={(e) => checkForCustomField(item.module_id)}>
              {' '}
              <SidebarMenuItem key={item.title} to={path} icon={item.icon} title={item.title} />
            </span>
          )
        }
      })}
    </div>
  )
}

export {SidebarMenuMain}

{
  /* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/customized-form'
        icon='social-media'
        title='Custom Form'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub to='/setting' title='Setting' icon='gear' fontIcon='bi-layers'>
        <SidebarMenuItem
          to='/setting/organization'
          title='Organization'
          icon='cheque'
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/setting/location-organization'
          title='Organization Location'
          icon='delivery-geolocation'
          hasBullet={false}
        />
        <SidebarMenuItem to='/setting/user' title='User' icon='security-user' hasBullet={false} />
        <SidebarMenuItem to='/setting/group' title='Group' icon='people' hasBullet={false} />
        <SidebarMenuItem to='/setting/role' title='Role' icon='share' hasBullet={false} />
        <SidebarMenuItem
          to='/setting/system-code'
          title='System Code'
          icon='wrench'
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/setting/holiday-list'
          title='Holiday List'
          icon='calendar'
          hasBullet={false}
        />
        <SidebarMenuItem to='/setting/workflow' title='Workflow' icon='theta' hasBullet={false} />
        <SidebarMenuItem to='/setting/module' title='Module' icon='file-sheet' hasBullet={false} />
      </SidebarMenuItemWithSub> */
}
