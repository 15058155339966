import instance from './instance'

export const createEmployeeList = (payload: any) => instance.post('modules-hrm/employee', payload)

export const getEmployeeList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`modules-hrm/employees?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateEmployee = (id: any, payload: any) => instance.post(`modules-hrm/employee/${id}`, payload)

export const deleteEmployee = (id: any) => instance.delete(`modules-hrm/employee/${id}`)

export const getEmployeeById = (id: number) => instance.get(`modules-hrm/employee/${id}`)
