import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {
  createHolidayPolicy,
  deleteHolidayPolicy,
  getHolidayPolicyById,
  getHolidayPolicyList,
  updateHolidayPolicy,
} from '../../services/HolidayPolicyService'

interface HolidayPolicyState {
  holidayPolicyDetailsList: any | null
  holidayPolicyDetails: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: HolidayPolicyState = {
  holidayPolicyDetailsList: null,
  holidayPolicyDetails: null,
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface GetHolidayPolicyByIdParams {
  id: string
}

interface UpdateHolidayPolicyParams {
  id: string | undefined
  data: any
}

// Thunks
export const getHolidayPolicyListApi = createAsyncThunk(
  'holidayPolicy/getHolidayPolicyList',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getHolidayPolicyList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const createHolidayPolicyApi = createAsyncThunk(
  'holidayPolicy/createHolidayPolicy',
  async (holidayPolicyData: any, {rejectWithValue}) => {
    try {
      const result = await createHolidayPolicy(holidayPolicyData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create Holiday Policy')
    }
  }
)

export const getHolidayPolicyByIdApi = createAsyncThunk(
  'holidayPolicy/getHolidayPolicyById',
  async ({id}: GetHolidayPolicyByIdParams, {rejectWithValue}) => {
    try {
      const result = await getHolidayPolicyById(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Holiday Policy not found')
    }
  }
)

// Update HolidayPolicy
export const updateHolidayPolicyApi = createAsyncThunk(
  'leavePolicy/updateHolidayPolicy',
  async ({id, data}: UpdateHolidayPolicyParams, {rejectWithValue}) => {
    try {
      const result = await updateHolidayPolicy(id, data)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update Holiday Policy')
    }
  }
)

export const deleteHolidayPolicyApi = createAsyncThunk(
  'leavePolicy/deleteHolidayPolicyApi',
  async (id: any, {rejectWithValue}) => {
    console.log('Delete Holiday', id)
    try {
      const result = await deleteHolidayPolicy(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to delete Holiday Policy')
    }
  }
)

// Slice
const HolidayPolicySlice = createSlice({
  name: 'holidayPolicy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get HolidayPolicy List
      .addCase(getHolidayPolicyListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getHolidayPolicyListApi.fulfilled, (state, action) => {
        console.log(action.payload)
        state.holidayPolicyDetailsList = action.payload.data
        state.status = 'succeeded'
      })
      .addCase(getHolidayPolicyListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create HolidayPolicy
      .addCase(createHolidayPolicyApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createHolidayPolicyApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createHolidayPolicyApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get HolidayPolicy By ID
      .addCase(getHolidayPolicyByIdApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getHolidayPolicyByIdApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.holidayPolicyDetails = action.payload
      })
      .addCase(getHolidayPolicyByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Update HolidayPolicy
      .addCase(updateHolidayPolicyApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateHolidayPolicyApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(updateHolidayPolicyApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Delete HolidayPolicy
      .addCase(deleteHolidayPolicyApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteHolidayPolicyApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(deleteHolidayPolicyApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default HolidayPolicySlice
