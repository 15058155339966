import instance from './instance'

const url = 'modules-hrm/holiday-templates'

export const getHolidayPolicyList = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `${url}?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&isDropdown=${isDropdown}`
  )

export const getHolidayPolicyById = (id: string) => instance.get(`${url}/${id}`)

export const createHolidayPolicy = (payload: any) => instance.post(url, payload)

export const updateHolidayPolicy = (id: any, payload: any) => instance.post(`${url}/${id}`, payload)

export const deleteHolidayPolicy = (id: number) => instance.delete(`${url}/${id}`)
