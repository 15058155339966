import instance from './instance'

export const createDesignation = (payload: any) => instance.post('modules-hrm/designations', payload)

export const getDesignationList = (page: number, pageSize: number, filterkey: any,isDropdown:number) =>
  instance.get(`modules-hrm/designations?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateDesignation = (id: any, payload: any) => instance.post(`modules-hrm/designations/${id}`, payload)

export const deleteDesignation = (id: any) => instance.delete(`modules-hrm/designations/${id}`)

export const getDesignationById = (id: number) => instance.get(`modules-hrm/designations/${id}`)
