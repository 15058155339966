import instance from './instance'

const url = 'modules-hrm/leave_template'

export const getLeavePolicyList = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `${url}?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&isDropdown=${isDropdown}`
  )

export const getLeavePolicyById = (id: string) => instance.get(`${url}/${id}`)

export const createLeavePolicy = (payload: any) => instance.post(url, payload)

export const updateLeavePolicy = (id: any, payload: any) => instance.post(`${url}/${id}`, payload)

export const deleteLeavePolicy = (id: string) => instance.delete(`${url}/${id}`)
