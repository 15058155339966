import instance from './instance'

const url = 'modules-hrm/general-setting'

export const getGeneralSettingList = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `${url}?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&isDropdown=${isDropdown}`
  )

export const createGeneralSetting = (payload: any) => instance.post(url, payload)

export const getGeneralSettingByType = (type: string) => instance.get(`${url}/${type}`)

export const updateGeneralSetting = (type: String, payload: any) =>
  instance.post(`${url}/${type}`, payload)

export const deleteGeneralSetting = (id: string) => instance.delete(`${url}/${id}`)
