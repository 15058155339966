import React, {useEffect} from 'react'
import {useFormik} from 'formik'
import 'react-toastify/dist/ReactToastify.css'
import {useDispatch, useSelector} from 'react-redux'
import {getOrganizationLocationListApi} from '../../../redux/slices/OrganizationLocationSlices'
import Select from 'react-select'
import {getDepartmentApi} from '../../../redux/slices/DepartmentSlice'
import {RootState} from '../../../redux/store'
import {getDesignationApi} from '../../../redux/slices/DesignationsSlice'
import {getSystemCodeByKeywordApi} from '../../../redux/slices/SystemCodeSlices'

const RoasterDetails: React.FC<{
  formValues: {
    organization_location_id: string
    department_id: string
    designation_id: string
    gender_id: string
  }
  setFormValues: (values: any) => void
  onSubmit: (values: any) => void
  onCreateNew: () => void
}> = ({formValues, setFormValues, onSubmit, onCreateNew}) => {
  const genderOptions = ['Male', 'Female', 'Other']
  const dispatch = useDispatch<any>()

  // const OrganizationLocationData = useSelector(
  //   (state: any) => state.organizationLocation.OrganizationLocationList?.data?.data || []
  // )

  // const fetchAllOrganizationLocations = () =>
  //   dispatch(
  //     getOrganizationLocationListApi({ pageNumber: 1, pageSize: 0, filterkey: '', isDropdown: 1 })
  //   )

  const departmentList = useSelector(
    (state: RootState) => state.department.departmentList?.data?.data || []
  )

  const fetchAllDepartmentList = () =>
    dispatch(getDepartmentApi({pageNumber: 1, pageSize: 0, filterkey: '', isDropdown: 1}))

  const designationList = useSelector(
    (state: RootState) => state.designations.designationList?.data?.data || []
  )

  const fetchAllDesignationList = () =>
    dispatch(getDesignationApi({pageNumber: 1, pageSize: 0, filterkey: '', isDropdown: 1}))

  const genderList = useSelector(
    (state: RootState) => state.systemCode.systemCodeDataByKeyword?.data?.data?.children || []
  )

  const getGenderList = () => dispatch(getSystemCodeByKeywordApi('Gender'))

  useEffect(() => {
    fetchAllDepartmentList()
    // fetchAllOrganizationLocations()
    fetchAllDesignationList()
    getGenderList()
  }, [])

  // const AllOrganizationLocationOption = OrganizationLocationData.map(
  //   (orgLoc: { organization_location_id: string; location_name: string }) => ({
  //     value: orgLoc.organization_location_id,
  //     label: orgLoc.location_name,
  //   })
  // )

  const AllDepartmentOption = departmentList.map(
    (depart: {organization_location_id: number; name: string; id: number}) => ({
      value: depart.id,
      label: depart.name,
    })
  )

  const AllDesignationOption = designationList.map(
    (desig: {organization_location_id: number; name: string; id: number}) => ({
      value: desig.id,
      label: desig.name,
    })
  )

  const AllGenderList = genderList.map((gender_id: {code: string; id: number}) => ({
    value: gender_id.id,
    label: gender_id.code,
  }))

  console.log(genderList)

  // Formik form setup with enableReinitialize for initialValues updates
  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFormValues(values) // Update state in Details component
      onSubmit(values) // Handle form submission
    },
  })

  const customStyles = {
    menu: (base: any) => ({
      ...base,
      borderRadius: '6px',
      marginTop: '0px',
      fontSize: '12px',
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? 'transparent' : 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? 'transparent' : 'transparent',
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#a1a5b7',
      fontSize: '14px',
    }),
    dropdownIndicator: (base: any, state: {selectProps: {menuIsOpen: any}}) => ({
      ...base,
      transition: 'all .4s ease',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    }),
  }

  return (
    <>
      <div className='card py-5 px-6'>
        {/* Header with title and buttons */}
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h2 className='m-0'>Employee Roaster</h2>
          <div>
            <button className='btn btn-outline-primary mx-2'>
              Sync Roaster (Next Financial Year)
            </button>
            <button className='btn btn-outline-secondary'>Shift Details</button>
          </div>
        </div>

        {/* Form with Formik */}
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='row g-2'>
            {/* <div className='col'>
              <label className='form-label fs-6 fw-bold text-dark'>
                Location
              </label>
              <Select
                name='location'
                options={AllOrganizationLocationOption}
                onChange={(selectedOption) => formik.setFieldValue('location', selectedOption)}
                styles={customStyles}
                placeholder='Select location'
                value={formik.values.location}
                className='form-control form-control-sm min-w-200px form-control-solid p-1'
              />
            </div> */}

            <div className='col'>
              <label className='form-label fs-6 fw-bold text-dark'>Department</label>
              <Select
                name='department_id'
                options={AllDepartmentOption}
                onChange={(selectedOption: any) =>
                  formik.setFieldValue('department_id', selectedOption ? selectedOption.value : '')
                }
                styles={customStyles}
                placeholder='Select department'
                value={AllDepartmentOption.filter((data: any) => data.value == formik.values.department_id)}
                className='form-control form-control-sm min-w-200px form-control-solid p-1'
                isClearable
              />
            </div>

            <div className='col'>
              <label className='form-label fs-6 fw-bold text-dark'>Designation</label>
              <Select
                name='designation_id'
                options={AllDesignationOption}
                onChange={(selectedOption: any) =>
                  formik.setFieldValue('designation_id', selectedOption ? selectedOption.value : '')
                }
                styles={customStyles}
                placeholder='Select designation'
                value={AllDesignationOption.filter((data: any) => data.value == formik.values.designation_id)}
                className='form-control form-control-sm min-w-200px form-control-solid p-1'
                isClearable
              />
            </div>

            <div className='col'>
          <label className='form-label fs-6 fw-bold text-dark'>Gender</label>
          <Select
            name='gender_id'
            options={AllGenderList}
            onChange={(selectedOption: any) =>
              formik.setFieldValue('gender_id', selectedOption ? selectedOption.value : '')
            }
            styles={customStyles}
            placeholder='Select gender'
            value={AllGenderList.filter((data: any) => data.value === formik.values.gender_id)}
            className='form-control form-control-sm min-w-200 form-control-solid p-1'
            isClearable
          />
        </div>


          </div>

          {/* Action Buttons */}
          <div className='mt-5 text-end'>
            <button
              type='button'
              onClick={() => {
                formik.submitForm()
                // onCreateNew() // Notify parent to show TableEmployeeRoaster
              }}
              className='btn btn-primary mx-2'
            >
              Search
            </button>
            {/* <button type='button' onClick={formik.submitForm} className='btn btn-secondary'>
              Edit
            </button> */}
          </div>
        </form>
      </div>
    </>
  )
}

export default RoasterDetails
