import React, {useState} from 'react'
import RoasterDetails from './RoasterDetails'
import {TableEmployeeRoaster} from './TableEmployeeRoaster'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../redux/store'
import {getRoasterListApi} from '../../../redux/slices/RoasterSlices'

const Details: React.FC = () => {
  const [showTable, setShowTable] = useState(false)
  const dispatch = useDispatch<AppDispatch>()

  // State for form values
  const [formValues, setFormValues] = useState({
    organization_location_id: '',
    department_id: '',
    designation_id: '',
    gender_id: '',
  })

  const handleFormSubmit = (values: any) => {
    console.log(values)
    dispatch(
      getRoasterListApi({
        pageNumber: 1,
        pageSize: 100,
        filterkey: values,
        isDropdown: 1,
      })
    )
    setShowTable(true)
  }

  return (
    <div>
      <RoasterDetails
        formValues={formValues}
        setFormValues={setFormValues}
        onSubmit={handleFormSubmit}
        onCreateNew={() => setShowTable(true)}
      />
      {showTable && <TableEmployeeRoaster />}
    </div>
  )
}

export default Details
