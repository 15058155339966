import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select';
import Input from 'react-select/dist/declarations/src/components/Input';

interface SearchProps {
  searchQuery: string;
  onSearchChange: (searchQuery: string) => void;
  className?: string,
  optionsList?:any,
  searchByValue?:string,
  onSearchByChange?:(searchBy: any)=> void,
}


const optionsTempList =[
  {"label":"Short Code",value:"short_code"},
  { "label":"Location Name",value:"location_name"},
  {"label":"organization Name",value:"orhanization_name"},
  {"label":"Created At",value:"created_at"},
]

const customStyles = {
  
  menu: (base: any) => ({
    ...base,
    borderRadius: ' 6px',
    marginTop: '0px',
    fontSize: '12px',
  }),

  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: 'transparent',
    minWidth:"130px",
    borderColor: state.isFocused ? 'transparent' : 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused ? 'transparent' : 'transparent',
    },
  }),

  container: (base: any)=>({
     ...base,
     minWidth:"150px",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a1a5b7',
    fontSize: '14px',
  }),
  dropdownIndicator: (base: any, state: { selectProps: { menuIsOpen: any } }) => ({
    ...base,
    transition: 'all .4s ease',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
}

const TableSearchComponent: React.FC<SearchProps> = ({ searchQuery, onSearchChange, className = "",optionsList, onSearchByChange }) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  return (
    <div className='w-100 d-flex gap-3'>
      <div className={`form-control bg-light w-100 w-sm-50 d-flex align-items-center ${className}`} style={{minWidth:"200px"}}>
        <input
          type='text'
          className='w-100 border-0 bg-light'
          style={{ outline: 'none' }}
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder='Search'
        />
        <i className='bi bi-search fs-4 pe-2'></i>
      </div>
      {/* <div className={`form-control bg-light w-100 w-sm-25 d-flex align-items-center ${className}`} style={{ minWidth: "130px" }}> */}
        {/* <ReactSelect
          // {...formik.getFieldProps('organization_id')}
          options={optionsTempList}
          className='form-control bg-light w-100 w-sm-25 p-1 '
          classNamePrefix='react-select'
          placeholder='Search By'
          // value={searchQuery}
          onChange={(selectedOption)=>{if(onSearchByChange)onSearchByChange(selectedOption)}}
          styles={customStyles}
        /> */}
      {/* </div> */}
    </div>
  );
}

export { TableSearchComponent }
