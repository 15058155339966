import instance from './instance'

export const createDepartment = (payload: any) => instance.post('modules-hrm/department', payload)

export const getDepartmentList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules-hrm/departments?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateDepartment = (id: any, payload: any) => instance.post(`modules-hrm/department/${id}`, payload)

export const deleteDepartment = (id: any) => instance.delete(`modules-hrm/department/${id}`)

export const getDepartmentById = (id: number) => instance.get(`modules-hrm/department/${id}`)
