import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

// import {createDepartment,getDepartmentList,getDepartmentById,updateDepartment,deleteDepartment} from '../../services/DepartmentService'
import {getEmployeeList,createEmployeeList,deleteEmployee,getEmployeeById,updateEmployee} from '../../services/EmployeeService'



interface GetEmployeeListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown:number
}


interface EmployeeState {
    employeeList: any
    createEmployeeData: any
    EmployeeData: any
    updateEmployeeData: any
  }


const initialState: EmployeeState = {
  employeeList: null,
  createEmployeeData: null,
  EmployeeData: null,
  updateEmployeeData: null,
}


export const createEmployeetApi = createAsyncThunk(
  'create-Employee/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createEmployeeList(payload)
      return result
    } catch (error) {
      console.error("API Error:", error); 
      return rejectWithValue({error})
    }
  }
)


export const getEmployeetApi = createAsyncThunk(
  'EmployeeList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetEmployeeListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getEmployeeList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



export const deleteEmployeeApi = createAsyncThunk(
  'Department/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteEmployee(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getEmployeeByIdApi = createAsyncThunk(
  'DepartmentById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getEmployeeById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const updateEmployeeApi = createAsyncThunk(
  'updateDepartment/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateEmployee(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)




const EmployeeSlice = createSlice({
  name: 'Role',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getEmployeetApi.fulfilled, (state, action) => {
        state.employeeList = action.payload
      })
      .addCase(createEmployeetApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createEmployeeData = action?.payload
      })
        .addCase(getEmployeeByIdApi.fulfilled, (state, action) => {
          state.EmployeeData = action.payload
        })
      .addCase(updateEmployeeApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateEmployeeData = action?.payload
      })


  },
})
export default EmployeeSlice
