import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import OrganizationSlice from './slices/OrganizationSlices'
import OrganizationLocationSlice from './slices/OrganizationLocationSlices'
import UserSlice from './slices/UserSlices'
import RoleSlice from './slices/RoleSlices'
import GroupSlice from './slices/GroupSlices'
import SystemCodeSlice from './slices/SystemCodeSlices'
import WorkflowSlice from './slices/WorkflowSlices'
import CustomSettingSlice from './slices/CustomSettingSlices'
import ModuleSlice from './slices/ModuleSlice'
import PermissionSlice from './slices/PermissionSlice'
import shiftReducer from '../redux/slices/ShiftSlice'
import deductionReducer from '../redux/slices/DeductionSlice'
import {useDispatch} from 'react-redux'
import ruleReducer from './slices/RuleSlice'
import OvertimeSlice from './slices/OvertimeSlice'
import EmployeeSlice from './slices/EmployeeSlice'
import OvertimeListReducer from './slices/OvertimeListSlice'
import ShiftTypeSlice from './slices/ShiftTypeSlice'
import EmployeeTypeSlice from './slices/EmployeeTypeSlice'
import authSlice from './slices/AuthSlices'
import DepartmentSlice from './slices/DepartmentSlice'
import DesignationsSlice from './slices/DesignationsSlice'
import RoasterSlice from './slices/RoasterSlices'
import LeavePolicySlice from './slices/LeavePolicySlice'
import HolidayPolicySlice from './slices/HolidayPolicySlice'
import GeneralSettingSlice from './slices/GeneralSettingSlice'

export const useAppDispatch = () => useDispatch<AppDispatch>()

// Configure the store
export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    organization: OrganizationSlice.reducer,
    organizationLocation: OrganizationLocationSlice.reducer,
    user: UserSlice.reducer,
    role: RoleSlice.reducer,
    group: GroupSlice.reducer,
    systemCode: SystemCodeSlice.reducer,
    workflow: WorkflowSlice.reducer,
    customSetting: CustomSettingSlice.reducer,
    module: ModuleSlice.reducer,
    permission: PermissionSlice.reducer,
    shift: shiftReducer.reducer,
    deduction: deductionReducer,
    rules: ruleReducer,
    OvertimeRules: OvertimeSlice.reducer,
    overtime: OvertimeListReducer,
    employee: EmployeeSlice.reducer,
    shiftType: ShiftTypeSlice.reducer,
    employeeType: EmployeeTypeSlice.reducer,
    department: DepartmentSlice.reducer,
    designations: DesignationsSlice.reducer,
    roaster: RoasterSlice.reducer,
    leave: LeavePolicySlice.reducer,
    holiday: HolidayPolicySlice.reducer,
    generalSetting: GeneralSettingSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

// Define RootState type
export type RootState = ReturnType<typeof store.getState>

// Define AppDispatch type
export type AppDispatch = typeof store.dispatch
