
import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getEmployeeTypeList  } from '../../services/EmployeeTypeService'



interface EmployeeTypeState {
  EmployeeTypeList: any

}

const initialState: EmployeeTypeState = {
  EmployeeTypeList: null,

}


export const getEmployeeTypeListApi = createAsyncThunk(
  'EmployeeList/api/get',
  async (
  ) => {
    try {
      const result = await getEmployeeTypeList()
      return result.data
    } catch (error) {
      return ({error})
    }
  }
)


// createOrganizationModule

const EmployeeTypeSlice = createSlice({
  name: 'EmployeeType',
  initialState,
  reducers: {
    addSidebarOption: (state: any, action: any) => {
      // if(state.organizationSettingData.sidebar)
      // state.organizationSettingData.settings_json=null
      console.log(state.organizationSettingData.settings_json)
      // state.organizationSettingData.settings_json.sidebar.push(action.payload.formdata)
      // console.log(state.organizationSettingData)
      return state
    },
    addSidebarChildOption: (state: any, action: any) => {
      console.log(state, action)
      // console.log(action.payload)
      // state.list = action?.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeTypeListApi.fulfilled, (state, action) => {
        state.EmployeeTypeList = action.payload
      })
     
  },
})

export const {addSidebarOption, addSidebarChildOption} = EmployeeTypeSlice.actions
export default EmployeeTypeSlice
