/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {currentUser, logIn, googleLogin} from '../../../services/AuthService'
import {useDispatch} from 'react-redux'

import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {GoogleLogin} from '@react-oauth/google'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  email: 'tech.admin@developerbox.co.in',
  password: 'admin@123',
}

export function Login() {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data} = await logIn({email: values.email, password: values.password})
        const token = data.data.token
        const modules = JSON.stringify(data.data.modules)
        localStorage.setItem('loginToken', token)
        localStorage.setItem('modules', modules)

        toast.success('Login Successful')
        if (data.success) {
          window.location.reload()
        }
      } catch (error) {
        console.error(error)
        setStatus('Invalid login credentials')
        setSubmitting(false)
        setLoading(false)
        toast.error('Login failed. Check your credentials.')
      }
    },
  })

  const handleGoogleLogin = async (credentialResponse: any) => {
    if (credentialResponse?.credential) {
      const tokenId = credentialResponse.credential
      try {
        const {data} = await googleLogin({idtoken: tokenId})
        localStorage.setItem('loginToken', data.data.data.token)
        toast.success('Login Successful')
        if (data.status === 200) {
          window.location.reload()
        }
      } catch (error) {
        console.error('Google login failed:', error)
        toast.error('Google login failed')
      }
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-12'>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => toast.error('Google login failed')}
            logo_alignment='center'
          />
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      {formik.status && (
        <div className='mb-lg-7 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {'is-valid': formik.touched.email && !formik.errors.email}
          )}
          type='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {'is-valid': formik.touched.password && !formik.errors.password}
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {loading ? (
            <span className='indicator-label'>Please wait...</span>
          ) : (
            <span className='indicator-label'>Continue</span>
          )}
        </button>
      </div>
    </form>
  )
}
