import {FC, useState, useEffect, useCallback} from 'react'
import {useDispatch, useSelector, TypedUseSelectorHook} from 'react-redux'
import {useTheme} from '../../../../_metronic/partials/layout/app-theme/ThemeContext'
import TableEmployeeRoasterHeader from '../../../../_metronic/partials/widgets/tables/TableEmployeeRoasterHeader'
import {TablesWidget} from '../../../../_metronic/partials/widgets'
import {getEmployeetApi} from '../../../redux/slices/EmployeeSlice'
import {AppDispatch, RootState} from '../../../redux/store'
import {createRoasterApi, getRoasterListApi} from '../../../redux/slices/RoasterSlices'
import {toast} from 'react-toastify'
import {getShiftApi} from '../../../redux/slices/ShiftSlice'

interface FormDataEntry {
  emp_id: number
  shift_id: number
  start_date: string
  end_date: string
}


interface FormData {
  [key: number]: FormDataEntry
}

const shiftMapping: {[key: string]: number} = {
  morning: 1,
  afternoon: 2,
  night: 3,
}


const TableEmployeeRoaster: FC = () => {
  const [loading, setLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [selectedRow, setSelectedRow] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [selectedShiftHeader, setSelectedShiftHeader] = useState('')
  const [formData, setFormData] = useState<FormData>({})
  const [roasterData, setRasterData] = useState([])

  const dispatch = useDispatch<AppDispatch>()
  const {theme} = useTheme()

  const roasterSelector = useSelector((state: RootState) => state.roaster)

  interface Shiftlist {
    id: number
    name: string
  }
  const Shift = useSelector(
    (state: RootState) => state.shift.shiftList?.data?.data
  ) as Shiftlist[]

  console.log(Shift);

  useEffect(() => {
    dispatch(getShiftApi({filterkey:"",pageNumber:9,pageSize:0}));
  }, [])  

  const shiftOptions =
      Shift?.map((type) => ({
        value: type.id,
        label: type.name,
      })) || []


      console.log("--------",shiftOptions);

  useEffect(() => {
    setLoading(roasterSelector.status === 'loading')
    setRasterData(roasterSelector.roasterDetailsList?.data || [])
    setSelectAll(selectedRow?.length === roasterData?.length && roasterData?.length > 0)
  }, [selectedRow, roasterData, roasterSelector.status])

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearchQuery(searchKey)
    }, 1000)
    return () => clearTimeout(debounceTimeout)
  }, [searchKey])

  // UseEffect to call getRoasterListApi only when debouncedSearchQuery has a value
  useEffect(() => {
    dispatch(
      getRoasterListApi({
        pageNumber: 1,
        pageSize: 100,
        filterkey: debouncedSearchQuery,
        isDropdown: 1,
      })
    )
  }, [debouncedSearchQuery, dispatch])

  const handleSearchChange = (searchQuery: string) => setSearchKey(searchQuery)

  const handleShiftHeaderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedShift = event.target.value
    setSelectedShiftHeader(selectedShift)
    updateFormDataForAllRows({shift_id: shiftMapping[selectedShift] || 0})
  }

  const updateFormDataForAllRows = (update: Partial<FormDataEntry>) => {
    setFormData((prevFormData) => {
      const updatedFormData = {...prevFormData}
      roasterData.forEach((row: any) => {
        updatedFormData[row.id] = {
          ...updatedFormData[row.id], // Spread the existing data first
          ...(updatedFormData[row.id]?.emp_id ? {} : {emp_id: row.id}), // Only set emp_id if not already set
          ...update, // Spread the update values afterward
        }
      })
      return updatedFormData
    })
  }

  const handleRowFieldChange = (
    id: number,
    field: 'start_date' | 'end_date' | 'shift_id',
    value: string
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: {
        ...prevFormData[id], // Spread existing data first
        ...(prevFormData[id]?.emp_id ? {} : {emp_id: id}), // Only set emp_id if not already set
        [field]: field === 'shift_id' ? value || 0 : value, // Map shift_id if necessary
      },
    }))
  }

  const validateFormData = () => {
    for (const entry of Object.values(formData)) {
      if (!entry.start_date || !entry.end_date || !entry.shift_id) {
        toast.error(
          'Please fill in all required fields (start date, end date, and shift) for each selected row.'
        )
        return false
      }
    }
    return true
  }

  const submitHandler = () => {
    const formattedData = {roasters: Object.values(formData)}
    if (!validateFormData()) return
    if (formattedData.roasters.length != 0) {
      dispatch(createRoasterApi(formattedData)).then((res: any) => {
        toast.success(res.payload.reason)
      })
    }
  }

  const handleHeaderDateChange = (field: 'start_date' | 'end_date', value: string) => {
    updateFormDataForAllRows({[field]: value})
  }

  const DateInput: FC<{
    row: any
    field: 'start_date' | 'end_date'
    placeholder: string
  }> = ({row, field, placeholder}) => {
    const toggleDateInputType = (
      e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>
    ) => {
      e.target.type = e.type === 'focus' ? 'date' : 'text'
      if (e.type === 'blur' && !e.target.value) {
        e.target.placeholder = placeholder
      }
    }

    return (
      <input
        type='text'
        className='form-control'
        placeholder={placeholder}
        disabled={!selectedRow.find((srow) => srow.id === row.id)}
        value={formData[row.id]?.[field] || ''}
        onFocus={toggleDateInputType}
        onBlur={toggleDateInputType}
        onChange={(e) => handleRowFieldChange(row.id, field, e.target.value)}
      />
    )
  }

  const ShiftSelectInput: FC<{row: any}> = ({row}) => (
    <select
      className='form-select'
      disabled={!selectedRow.find((srow) => srow.id === row.id)}
      value={formData[row.id]?.shift_id || ''}
      onChange={(e) => handleRowFieldChange(row.id, 'shift_id', e.target.value)}
    >
      <option value='' disabled>
            Shift
          </option>
          {shiftOptions.map(option=>(
            <option value={option.value}>
            {option.label}
          </option>
          ))}
    </select>
  )

  const columnData = [
    {name: 'ID', selector: (row: any) => row?.id, sortable: true},
    {name: 'Employee Name', selector: (row: any) => row?.first_name, sortable: true},
    {name: 'Department', selector: (row: any) => row?.department_details?.name, sortable: true},
    {name: 'Designation', selector: (row: any) => row?.designation_details?.name, sortable: true},
    // {name: 'Section', selector: (row: any) => row?.section, sortable: true},
    {
      name: (
        <select
          className='form-select'
          value={selectedShiftHeader}
          onChange={handleShiftHeaderChange}
          disabled={!selectAll}
        >
          <option value='' disabled>
            Shift
          </option>
          {shiftOptions.map(option=>(
            <option value={option.value}>
            {option.label}
          </option>
          ))}
        
          {/* <option value='morning'>Morning</option>
          <option value='afternoon'>Afternoon</option>
          <option value='night'>Night</option> */}
        </select>
      ),
      cell: (row: any) => <ShiftSelectInput row={row} />,
      sortable: false,
    },
    {
      name: (
        <input
          type='text'
          className='form-control'
          placeholder='Start Date'
          disabled={!selectAll}
          onFocus={(e) => (e.target.type = 'date')}
          onBlur={(e) => (e.target.type = 'text')}
          onChange={(e) => handleHeaderDateChange('start_date', e.target.value)}
        />
      ),
      cell: (row: any) => <DateInput row={row} field='start_date' placeholder='Start Date' />,
      sortable: false,
    },
    {
      name: (
        <input
          type='text'
          className='form-control'
          placeholder='End Date'
          disabled={!selectAll}
          onFocus={(e) => (e.target.type = 'date')}
          onBlur={(e) => (e.target.type = 'text')}
          onChange={(e) => handleHeaderDateChange('end_date', e.target.value)}
        />
      ),
      cell: (row: any) => <DateInput row={row} field='end_date' placeholder='End Date' />,
      sortable: false,
    },
  ]

  return (
    <div className='card'>
      <TableEmployeeRoasterHeader searchKey={searchKey} handleSearchChange={handleSearchChange} />
      <div className='card-body p-0 shadow-sm'>
        <TablesWidget
          data={roasterData}
          columns={columnData}
          isLoading={loading}
          selectableRows
          onSelectedRowsChange={(selected) => setSelectedRow(selected.selectedRows)}
          showPagination={false}
        />
      </div>
      <div className='m-5 text-end'>
        <button type='button' onClick={submitHandler} className='btn btn-primary mx-2'>
          Submit
        </button>
      </div>
    </div>
  )
}

export {TableEmployeeRoaster}
