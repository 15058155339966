import React, {useState} from 'react'
import {TableSearchComponent} from '../search-input/TableSearchComponent'
import {useTheme} from '../../layout/app-theme/ThemeContext'

const TableEmployeeRoasterHeader = ({
  searchKey,
  handleSearchChange,
}: {
  searchKey?: string
  handleSearchChange?: (searchQuery: string) => void
}) => {
  const {theme} = useTheme()
  const [startDate, setStartDate] = useState('2023-04-01') // Format as YYYY-MM-DD for date input
  const [endDate, setEndDate] = useState('2024-03-31')

  return (
    <div
      className={`card-header py-7 flex-wrap flex-md-nowrap w-100 justify-content-between align-items-center gap-3 shadow-sm`}
      style={{
        position: 'relative',
        paddingLeft: '10px',
      }}
    >
      {/* Search Component */}
      {typeof searchKey !== 'undefined' && typeof handleSearchChange !== 'undefined' ? (
        <TableSearchComponent searchQuery={searchKey} onSearchChange={handleSearchChange} />
      ) : null}

      {/* Financial Year Header and Date Range */}
      <div className='d-flex ms-auto align-items-center gap-3'>
        <div className='d-flex flex-column me-3'>
          <span className='form-label m-0'>Financial Year</span>
        </div>
        <div className='d-flex flex-column'>
          <input
            type='date'
            id='startDate'
            className='form-control'
            value={startDate}
            disabled
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className='d-flex flex-column'>
          <input
            type='date'
            id='endDate'
            className='form-control'
            value={endDate}
            disabled
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>

      {/* Left Border Styling */}
      <span
        className={`border border-${theme} border-left-4`}
        style={{
          content: '',
          position: 'absolute',
          top: 12,
          left: 0,
          height: '70%',
          width: '5px',
        }}
      />
    </div>
  )
}

export default TableEmployeeRoasterHeader
