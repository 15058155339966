import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Details from './components/Details'

const defaultBreadCrumbs: Array<PageLink> = [
  {
    title: 'HRM',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

const RoasterPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={defaultBreadCrumbs}>Roaster</PageTitle>
              <Details />
            </>
          }
        />
      </Route>

      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export default RoasterPage
