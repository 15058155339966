import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {
  createGeneralSetting,
  deleteGeneralSetting,
  getGeneralSettingByType,
  getGeneralSettingList,
  updateGeneralSetting,
} from '../../services/GeneralSettingService'

interface GeneralSettingState {
  generalSettingDetailsList: any | null
  generalSettingDetails: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: GeneralSettingState = {
  generalSettingDetailsList: null,
  generalSettingDetails: null,
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

export interface GetGeneralSettingByTypeParams {
  type: string
}

export interface UpdateGeneralSettingParams {
  type: string
  data: any
}

// Thunks
export const getGeneralSettingListApi = createAsyncThunk(
  'generalSetting/getGeneralSettingList',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getGeneralSettingList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const createGeneralSettingApi = createAsyncThunk(
  'generalSetting/createGeneralSetting',
  async (generalSettingData: any, {rejectWithValue}) => {
    try {
      const result = await createGeneralSetting(generalSettingData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create Leave Policy')
    }
  }
)

export const getGeneralSettingByTypeApi = createAsyncThunk(
  'generalSetting/getGeneralSettingByType',
  async ({type}: GetGeneralSettingByTypeParams, {rejectWithValue}) => {
    try {
      const result = await getGeneralSettingByType(type)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Leave Policy not found')
    }
  }
)

// Update GeneralSetting
export const updateGeneralSettingApi = createAsyncThunk(
  'generalSetting/updateGeneralSetting',
  async ({type, data}: UpdateGeneralSettingParams, {rejectWithValue}) => {
    try {
      const result = await updateGeneralSetting(type, data)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update Leave Policy')
    }
  }
)

export const deleteGeneralSettingApi = createAsyncThunk(
  'generalSetting/deleteGeneralSettingApi',
  async (id: any, {rejectWithValue}) => {
    console.log('Delete Leave', id)
    try {
      const result = await deleteGeneralSetting(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to delete Leave Policy')
    }
  }
)

// Slice
const GeneralSettingSlice = createSlice({
  name: 'generalSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get GeneralSetting List
      .addCase(getGeneralSettingListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getGeneralSettingListApi.fulfilled, (state, action) => {
        state.generalSettingDetailsList = action.payload
        state.status = 'succeeded'
      })
      .addCase(getGeneralSettingListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create GeneralSetting
      .addCase(createGeneralSettingApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createGeneralSettingApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createGeneralSettingApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get GeneralSetting By Type
      .addCase(getGeneralSettingByTypeApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getGeneralSettingByTypeApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.generalSettingDetails = action.payload?.data?.data
      })
      .addCase(getGeneralSettingByTypeApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Update GeneralSetting
      .addCase(updateGeneralSettingApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateGeneralSettingApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(updateGeneralSettingApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Delete GeneralSetting
      .addCase(deleteGeneralSettingApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteGeneralSettingApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(deleteGeneralSettingApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default GeneralSettingSlice
