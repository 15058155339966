import instance from './instance';


export const createRule = (payload: any) => {
    return instance.post('modules-hrm/rule', payload);
};
export const getRule = (pageNumber: number, pageSize: number, ruleTypeId: string, filterkey: string) => {
    return instance.get(`/modules-hrm/rule?rule_type_id=${ruleTypeId}&page=${pageNumber}&size=${pageSize}&filterkey=${filterkey}`);
};

export const getRuleById = (id: number) => instance.get(`/modules-hrm/rule/${id}`)


export const updateRule = (id: number) => instance.get(`/modules-hrm/rule/${id}`)

export const updateRules = (id: any, payload: any) => instance.post(`/modules-hrm/rule/${id}`, payload)

export const deleteRule = (id: any) =>    instance.delete(`/modules-hrm/rule/${id}`)