import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../app/services/instance';


interface DeductionState {
  deductionTypes: DeductionType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

interface DeductionType {
  id: number;
  name: string;
}

const initialState: DeductionState = {
  deductionTypes: [],
  status: 'idle',
};

export const fetchDeductionTypesApi = createAsyncThunk(
  'deduction/fetchDeductionTypes',
  async () => {
    const response = await axiosInstance.get('/modules-hrm/deduction-type'); 
    console.log(response.data.data.data)
    return response.data.data.data;
  }
);

const deductionSlice = createSlice({
  name: 'deduction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeductionTypesApi.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeductionTypesApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.deductionTypes = action.payload;
      })
      .addCase(fetchDeductionTypesApi.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = deductionSlice.actions;
export default deductionSlice.reducer;
