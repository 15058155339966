import React from 'react'
import DataTable from 'react-data-table-component'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import Loader from '../../../layout/components/loader/Loader'

interface TablesWidgetProps {
  data: any
  columns: any
  handlePerRowsChange?: (newPerPage: number, page: number) => void
  handlePageChange?: (Page: number) => void
  totalRows?: number
  showPagination: boolean
  onRowClicked?: (row: any, e: any) => void
  selectableRows?: boolean
  onSelectedRowsChange?: (selected: any) => any
  isLoading?: boolean
}

const TablesWidget: React.FC<TablesWidgetProps> = ({
  data,
  columns,
  handlePerRowsChange = () => {},
  handlePageChange = () => {},
  totalRows = 0,
  showPagination,
  onRowClicked,
  onSelectedRowsChange,
  selectableRows,
  isLoading,
}) => {
  const {mode} = useThemeMode()

  const customStyles = {
    rows: {
      style: {
        minHeight: '56px',
        borderBottomWidth: '1px',
        borderBottomColor: mode === 'dark' ? '#dee2e6' : '#ced4da',
      },
    },
    headCells: {
      style: {
        backgroundColor: mode === 'dark' ? '#343a40' : '#f8f9fa',
        color: mode === 'dark' ? '#ffffff' : '#212529',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    pagination: {
      style: {
        backgroundColor: mode === 'dark' ? '#343a40' : '#f8f9fa',
        color: mode === 'dark' ? '#ffffff' : '#212529',
      },
    },
  }

  return (
    <div className='overflow-hidden'>
      <DataTable
        responsive
        pagination={showPagination}
        paginationServer
        columns={columns}
        data={data}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        onRowClicked={onRowClicked}
        selectableRows={selectableRows}
        progressPending={isLoading}
        progressComponent={<Loader />}
        onSelectedRowsChange={onSelectedRowsChange}
      />
    </div>
  )
}

export {TablesWidget}
