import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../services/instance';

interface OvertimeState {
  overtimeTypes: OvertimeType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

interface OvertimeType {
  id: number;
  name: string;
}

const initialState: OvertimeState = {
  overtimeTypes: [],
  status: 'idle',
};

export const fetchOvertimeTypesApi = createAsyncThunk(
  'overtime/fetchOvertimeTypes',
  async () => {
    const response = await axiosInstance.get('/modules-hrm/overtime-type');
    console.log(response.data.data.data);
    return response.data.data.data;
  }
);

const OvertimeListSlice = createSlice({
  name: 'overtime',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOvertimeTypesApi.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOvertimeTypesApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.overtimeTypes = action.payload;
      })
      .addCase(fetchOvertimeTypesApi.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = OvertimeListSlice.actions;
export default OvertimeListSlice.reducer;
