import instance from './instance'

export const createShift = (payload: any) => {
  return instance.post('modules-hrm/employee-shift', payload)
}

export const getShift = () => {
  return instance.get(`modules-hrm/employee-shift`)
}

export const deleteShift = (shiftId: string) => {
  return instance.delete(`modules-hrm/employee-shift/${shiftId}`)
}

export const fetchShiftById = (shiftId: string) => {
  return instance.get(`modules-hrm/employee-shift/${shiftId}`)
}

export const updateShift = (shiftId: string, payload: any) => {
  return instance.post(`modules-hrm/employee-shift/${shiftId}`, payload)
}
