
  import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
  import { getShiftTypeList  } from '../../services/ShiftTypeService'


  
  interface ShiftTypeState {
    ShiftTypeList: any

  }
  
  const initialState: ShiftTypeState = {
    ShiftTypeList: null,

  }
  
  
  export const getShiftTypeListApi = createAsyncThunk(
    'ShiftTypeList/api/get',
    async (
    ) => {
      try {
        const result = await getShiftTypeList()
        return result.data
      } catch (error) {
        return ({error})
      }
    }
  )
  
  // createOrganizationModule
  
  const ShiftTypeSlice = createSlice({
    name: 'ShiftType',
    initialState,
    reducers: {
      addSidebarOption: (state: any, action: any) => {
        // if(state.organizationSettingData.sidebar)
        // state.organizationSettingData.settings_json=null
        console.log(state.organizationSettingData.settings_json)
        // state.organizationSettingData.settings_json.sidebar.push(action.payload.formdata)
        // console.log(state.organizationSettingData)
        return state
      },
      addSidebarChildOption: (state: any, action: any) => {
        console.log(state, action)
        // console.log(action.payload)
        // state.list = action?.payload
      },
    },
  
    extraReducers: (builder) => {
      builder
        .addCase(getShiftTypeListApi.fulfilled, (state, action) => {
          state.ShiftTypeList = action.payload
        })
       
    },
  })
  
  export const {addSidebarOption, addSidebarChildOption} = ShiftTypeSlice.actions
  export default ShiftTypeSlice
  