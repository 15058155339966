import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createRule,getRuleById, updateRules,deleteRule } from '../../services/RulesService';



interface GetUserListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

export const createRuleApi = createAsyncThunk(
  'rules/createRule',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await createRule(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);


export const getRuleByIdApi = createAsyncThunk(
  'RuleById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getRuleById(id)
      // console.log(result.data.data.data)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateRuleApi = createAsyncThunk(
  'updateRule/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateRules(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const deleteRuleApi = createAsyncThunk(
  'Rule/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteRule(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)




const initialState = {
  status: 'idle',
  error: null as string | null,
  rule: null,
  userData: null, 
  updateRoleData:null
};



const ruleSlice = createSlice({
  name: 'rules', 
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRuleApi.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createRuleApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.rule = action.payload;
      })
      .addCase(createRuleApi.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string | null;
      })
      .addCase(getRuleByIdApi.fulfilled, (state, action) => {
        state.userData = action.payload
      })
      // .addCase(updateRuleApi.fulfilled, (state, action) => {
      //   console.log(action?.payload)
      //   state.updateRoleData = action?.payload
      // })
     
  },
});

export default ruleSlice.reducer;
