// import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
// // import { createRule,getRuleById, updateRules,deleteRule } from '../../services/RulesService';
// import {getShift, createShift, deleteShift, fetchShiftById} from '../../services/ShiftService'

// interface GetShiftListParams {
//   pageNumber: number
//   pageSize: number
//   filterkey: any
// }

// interface ShiftState {
//   getShiftList: any
//   shiftData: any
//   createShiftList: any
//   updateUserData: any
//   AllOrganizationsData: any
//   AllUserListData: any
// }

// const initialState: ShiftState = {
//   getShiftList: null,
//   shiftData: null,
//   createShiftList: null,
//   updateUserData: null,
//   AllOrganizationsData: null,
//   AllUserListData: null,
// }

// export const getShiftApi = createAsyncThunk(
//   'getShiftList/api/get',
//   async ({pageNumber, pageSize, filterkey}: GetShiftListParams, {rejectWithValue}) => {
//     try {
//       // Provide a default value if ruleTypeId is undefined
//       const result = await getShift()
//       return result.data
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )
// export const createShiftApi = createAsyncThunk(
//   'rules/createRule',
//   async (payload: any, {rejectWithValue}) => {
//     try {
//       const response = await createShift(payload)
//       return response.data
//     } catch (error: any) {
//       return rejectWithValue(error.response?.data || 'Something went wrong')
//     }
//   }
// )

// export const getShiftByIdApi = createAsyncThunk(
//   'ShiftById/api/get',
//   async (id: any, {rejectWithValue, fulfillWithValue}) => {
//     try {
//       const result = await fetchShiftById(id)
//       // console.log(result.data.data.data)
//       return result.data
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )

// // export const updateRuleApi = createAsyncThunk(
// //   'updateRule/api/put',

// //   async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
// //     try {
// //       const result = await updateRules(id, payload)
// //       return result
// //     } catch (error) {
// //       return rejectWithValue({error})
// //     }
// //   }
// // )
// export const deleteShiftApi = createAsyncThunk(
//   'Rule/api/delete',
//   async (id: any, {rejectWithValue, fulfillWithValue}) => {
//     try {
//       const result = await deleteShift(id)
//       return result.data
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )

// const ruleSlice = createSlice({
//   name: 'rules',
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder

//       .addCase(getShiftApi.fulfilled, (state, action) => {
//         state.getShiftList = action.payload
//       })
//       .addCase(createShiftApi.fulfilled, (state, action) => {
//         state.shiftData = action.payload
//       })

//     .addCase(getShiftByIdApi.fulfilled, (state, action) => {
//       state.shiftData = action.payload
//     })
//     // .addCase(updateRuleApi.fulfilled, (state, action) => {
//     //   console.log(action?.payload)
//     //   state.updateRoleData = action?.payload
//     // })
//   },
// })

// export default ruleSlice.reducer

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {
  getShift,
  createShift,
  deleteShift,
  fetchShiftById,
  updateShift,
} from '../../services/ShiftService'

interface GetShiftListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface ShiftState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
  shift: any
  shiftList: any
  selectedShift: any | null
}

const initialState: ShiftState = {
  status: 'idle',
  error: null,
  shift: null,
  shiftList: null,
  selectedShift: null,
}

export const createShiftApi = createAsyncThunk('shift/createShift', async (payload: any) => {
  const response = await createShift(payload)
  console.log('API Response:', response)
  return response.data
})

export const getShiftApi = createAsyncThunk(
  'getShiftList/api/get',
  async ({pageNumber, pageSize, filterkey}: GetShiftListParams, {rejectWithValue}) => {
    try {
      // Provide a default value if ruleTypeId is undefined
      const result = await getShift()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const fetchShiftByIdApi = createAsyncThunk(
  'shift/fetchShiftById',
  async (shiftId: string) => {
    const response = await fetchShiftById(shiftId)
    console.log('Fetch By Id API Response:', response)
    return response.data
  }
)

export const updateShiftApi = createAsyncThunk(
  'shift/updateShift',
  async ({shiftId, payload}: {shiftId: string; payload: any}) => {
    const response = await updateShift(shiftId, payload)
    console.log('Update API Response:', response)
    return response.data
  }
)

export const deleteShiftApi = createAsyncThunk('shift/deleteShift', async (shiftId: string) => {
  const response = await deleteShift(shiftId)
  console.log('Delete API Response:', response)
  return response.data
})

const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createShiftApi.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(createShiftApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.shift = action.payload
      })
      .addCase(createShiftApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || 'Failed to create shift'
      })

      .addCase(getShiftApi.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getShiftApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.shiftList = action.payload
      })
      .addCase(getShiftApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || 'Failed to fetch shifts'
      })

      .addCase(fetchShiftByIdApi.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchShiftByIdApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.shift = action.payload
      })
      .addCase(fetchShiftByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || 'Failed to fetch shift details'
        console.error('Fetch Shift Error:', action.error)
      })

      .addCase(updateShiftApi.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(updateShiftApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.shift = action.payload
      })
      .addCase(updateShiftApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || 'Failed to update shift'
      })

      .addCase(deleteShiftApi.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteShiftApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.shift = action.payload
      })
      .addCase(deleteShiftApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || 'Failed to delete shift'
      })
  },
})

export default shiftSlice
