import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getRule} from '../../services/RulesService'
import {createRule,updateRule} from '../../services/RulesService'

interface RuleListParam {
  pageNumber: number
  pageSize: number
  filterkey: any
  ruleTypeId?: string;

}

interface RuleState {
  getRuleList: any
  userData: any
  createRuleList: any
  updateUserData: any
  AllOrganizationsData: any
  AllUserListData: any
}

const initialState: RuleState = {
  getRuleList: null,
  userData: null,
  createRuleList: null,
  updateUserData: null,
  AllOrganizationsData: null,
  AllUserListData: null,
}
// createUserApi

export const createRuleApi = createAsyncThunk(
  'createRuleApi/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createRule(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getRuleApi = createAsyncThunk(
  'getRuleList/api/get',
  async (
    { pageNumber, pageSize, ruleTypeId, filterkey }: RuleListParam, 
    { rejectWithValue }
  ) => {
    try {
      // Provide a default value if ruleTypeId is undefined
      const result = await getRule(pageNumber, pageSize, ruleTypeId || '', filterkey);
      return result.data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);



export const updateRuleApi = createAsyncThunk(
  'UserById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await updateRule(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// export const updateRuleApi = createAsyncThunk(
//   'updateUserApi/api/put',

//   async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
//     try {
//       const result = await updateRule(id, payload)
//       return result
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )

// export const getAllOrganizationsApi = createAsyncThunk(
//   'Organizations/api/get',
//   async (_, {rejectWithValue, fulfillWithValue}) => {
//     try {
//       const result = await getAllOrganizations()
//       return result.data
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )
// export const getAllUserListApi = createAsyncThunk(
//   'AllUserList/api/get',
//   async (_, {rejectWithValue, fulfillWithValue}) => {
//     try {
//       const result = await getAllUserList()
//       return result.data
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRuleApi.fulfilled, (state, action) => {
        state.getRuleList = action.payload
      })
      .addCase(updateRuleApi.fulfilled, (state, action) => {
        state.updateUserData = action.payload
      })
      .addCase(createRuleApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createRuleList = action?.payload
      })
    //   .addCase(updateUserApi.fulfilled, (state, action) => {
    //     console.log(action?.payload)
    //     state.updateUserData = action?.payload
    //   })
    //   .addCase(getAllOrganizationsApi.fulfilled, (state, action) => {
    //     state.AllOrganizationsData = action.payload
    //   })
    //   .addCase(getAllUserListApi.fulfilled, (state, action) => {
    //     state.AllUserListData = action.payload
    //   })
  },
})
export default UserSlice
