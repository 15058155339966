import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {getRoasterList, createRoaster, getRoasterById} from '../../services/RoasterService'

interface RoasterState {
  roasterDetailsList: any | null
  roasterDetails: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: RoasterState = {
  roasterDetailsList: null,
  roasterDetails: null,
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface GetRoasterByIdParams {
  id: number
}

// Thunks
export const getRoasterListApi = createAsyncThunk(
  'roaster/getRoasterList',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getRoasterList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const createRoasterApi = createAsyncThunk(
  'roaster/createRoaster',
  async (roasterData: any, {rejectWithValue}) => {
    try {
      const result = await createRoaster(roasterData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create roaster')
    }
  }
)

export const getRoasterByIdApi = createAsyncThunk(
  'roaster/getRoasterById',
  async ({id}: GetRoasterByIdParams, {rejectWithValue}) => {
    try {
      const result = await getRoasterById(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Roaster not found')
    }
  }
)

// Slice
const RoasterSlice = createSlice({
  name: 'roaster',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Roaster List
      .addCase(getRoasterListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getRoasterListApi.fulfilled, (state, action) => {
        state.roasterDetailsList = action.payload.data
        state.status = 'succeeded'
      })
      .addCase(getRoasterListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create Roaster
      .addCase(createRoasterApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createRoasterApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createRoasterApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get Roaster By ID
      .addCase(getRoasterByIdApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getRoasterByIdApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.roasterDetails = action.payload
      })
      .addCase(getRoasterByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default RoasterSlice
